<template>

<MDBNavbar expand="lg"   bg="white" class="shadow-0" container>
    <MDBNavbarBrand href="#"><img src="./assets/logo.png" alt="Knowledge-Pathway logo" style="height: 50px"></MDBNavbarBrand>
    <MDBNavbarToggler
      @click="collapse1 = !collapse1"
      target="#navbarSupportedContent"
    ></MDBNavbarToggler>
    <MDBCollapse v-model="collapse1" id="navbarSupportedContent">
      <MDBNavbarNav class="mb-2 mb-lg-0">
        <MDBNavbarItem to="/" active>
          <h5>Home</h5>
        </MDBNavbarItem>
        <MDBNavbarItem href="/#technology">
                    <h5 >Technology</h5>


        </MDBNavbarItem>
         <MDBNavbarItem href="/#clients">
                     <h5 >Clients</h5>
        </MDBNavbarItem>
        <MDBNavbarItem to="team">
                     <h5 >About Us</h5>
        </MDBNavbarItem>

         <MDBNavbarItem href="/#contact">
                     <h5 >Contact</h5>


        </MDBNavbarItem>



      </MDBNavbarNav>

    </MDBCollapse>
  </MDBNavbar>


  <!-- <div
    id="nav"
    class="navbar navbar-expand-lg navbar-default bg-primary shadow-0"
  >
    <router-link to="/"
      ><img
        src="./assets/rhino_logo_white.png"
        alt="Knowledge-Pathway Logo"
        style="height: 70px"
    /></router-link>
    <div class="container-fluid justify-content-end text-align-end text-light">
      <router-link to="/" class="px-3 text-light menu-item">Home</router-link>
      <a href="#approach" class="px-3 text-light menu-item">Approach</a>
      <a href="#clients" class="px-3 text-light menu-item">Clients</a>
      <a href="#technology" class="px-3 text-light menu-item">Technology</a>
      <a href="#contact" class="px-3 text-light menu-item">Contact</a>
    </div>
  </div> -->
  <router-view />
</template>

<script>
  import {
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,

  } from 'mdb-vue-ui-kit';
  import { ref } from 'vue';
  export default {
    components: {
      MDBNavbar,
      MDBNavbarToggler,
      MDBNavbarBrand,
      MDBNavbarNav,
      MDBNavbarItem,
      MDBCollapse,

    },
    setup() {
      const collapse1 = ref(false);
      const dropdown1 = ref(false);
      return {
        collapse1,
        dropdown1
      }
    }
  };
</script>

<style lang="scss">
@import "./assets/_variables.scss";
@import "~mdb-vue-ui-kit/src/scss/index.pro.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.menu-item:hover {
  border-bottom: 1px solid #008080;
  // background-color: red;
}

</style>
