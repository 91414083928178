
import { defineComponent } from "vue";
import { MDBInput } from "mdb-vue-ui-kit";
import emailjs from "emailjs-com";

export default defineComponent({
  name: "Hello",
  data() {
    return {
      formsInfo: { fname: "", lname: "", email: "", comment: "" },
    };
  },
  components: {
    MDBInput,
  },
  methods: {
    resetfunction() {
      (this.formsInfo.fname = ""),
        (this.formsInfo.lname = ""),
        (this.formsInfo.email = ""),
        (this.formsInfo.comment = "");
    },
    SubmitrHandler() {
      // (serviceID: string, templateID: string, templatePrams?: Record<string, unknown> | undefined, userID?: string | undefined)
      emailjs.send(
        "service_yfqz153",
        "template_2yqk3wm",
        this.formsInfo,
        "jqebLeZDZngIzgHgg"
      );
      this.resetfunction();
    },
  },
});
