
import { defineComponent, ref } from "vue";
import Client from "@/types/client";
import { MDBAnimation } from "mdb-vue-ui-kit";

export default defineComponent({
  name: "FeaturedClients",
  components: {
    MDBAnimation,
  },
  setup() {
    const clients = ref<Client[]>([
      {
        imageUrl: require("../../src/assets/clients-logos/SCHS.png"),
        description: "Saudi Commission For Health Specialties",
        id: "1",
        delay: 100,
      },
      {
        imageUrl: require("../../src/assets/clients-logos/hla.png"),
        description: "Healthcare Leadership Academy",
        id: "2",
        delay: 200,
      },

    ]);
    return {
      clients,
    };
  },
});
